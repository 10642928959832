<template>
  <div class="content">

    <div style="background: white;border-radius: 16px;padding: 30px 16px 36px 16px">
      <p class="title">
        有奖调研问卷调研活动说明：
      </p>
      <p class="text-content">
        问卷为商业有偿调研，在回答所有问题之后，我们会要求您关注平台公众号并使用手机注册登录便于平台对您现金打款。
      </p>

      <p class="title margin-top-30">奖励发放规则：</p>
      <p class="text-content">1、奖励内容：微信现金红包 <span class="text-red">{{ price }}</span> 元</p>
      <p class="text-content">2、奖励方式：微信公众号打款</p>
      <p class="text-content">3、发放周期：项目结束后等待客户审核后发放</p>
      <p class="text-content">4、奖励对象：符合调研目标要求通过问卷审核的用户</p>

      <p class="title margin-top-30">特别提示：</p>
      <p class="text-content">
        关注公众号用户必须已经完成微信的用户实名制，否则打款不能成功，未通过审核用户，我们会告知审核结果。
      </p>
      <p class="text-content" style="margin-top: 11px">
        本次调研不是试图销售任何产品或者服务，请放心填写，根据相关法律法规对于个人信息权益的保护，您的回答绝对保密，我们不会将您的信息泄露给任何第三方。
      </p>
    </div>

    <van-checkbox v-model="checked" shape="square" style="margin-top: 16px">我已经阅读并理解同意</van-checkbox>

    <div style="width: 100%;text-align: center;margin-top: 30px; padding-bottom: 30px">
      <van-button color="linear-gradient(to right, #7441a8, #70429f)" @click="goSurvey">进入问卷</van-button>
    </div>
  </div>
</template>

<script>
  import {Toast} from "vant";
  import untils from "@/until/until";

  export default {
    name: "guide",
    data() {
      return {
        checked: false,
        price: 0,
        formData: {},
        deviceInfo: {}
      }
    },
    created() {
      if (this.$route.query.skip){
        this.navigateToSurvey()
      }else {
        this.submitEvent("survey_guide", (success, res)=>{

        })
      }
    },
    mounted() {
      this.getActivity(this.$route.query.id)
    },
    methods: {
      submitEvent(event, callback){
        let UserInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
        this.$api.my.submitEvent({
          "event": event,
          "activityId": this.$route.query.id,
          "userId": UserInfo ? UserInfo.id : "",
          "nickname": UserInfo ? UserInfo.nickName : ""
        }, (success, res)=>{
          callback(success, res)
        })
      },
      //获取活动详情
      getActivity(id) {
        this.price = this.$route.query.num
      },
      goSurvey(){
        if (!this.checked){
          Toast("请阅读并同意以上内容")
          return
        }
        this.$toast.loading({
          message: '加载中...',
          forbidClick: true,
          loadingType: 'spinner',
          duration: 0
        });

        this.submitEvent("survey_start", (success, res)=>{
          this.$toast.clear()

          this.navigateToSurvey()
        })
      },
      navigateToSurvey(){
        if (untils.isWechat()){
          location.href = location.protocol +'//'+ location.host + "/prod-api/survey/detail/" + this.$route.query.id
        }else {
          window.open(location.protocol +'//'+ location.host + "/prod-api/survey/detail/" + this.$route.query.id)
        }
      }
    }
  }
</script>

<style scoped lang="less">
  .content{
    padding: 30px;
    background: #F6F7F9;
    height: 100vh;
    //color: #70429f;

    .title {
      font-size: 32px;
      font-weight: bold;
      color: black;
    }

    .text-content{
      font-size: 28px;
      color: #333333;
    }

    .margin-top-30{
      margin-top: 30px;
    }

    .text-red{
      font-size: 32px;
      color: red;
      font-weight: 500;
    }

    /deep/ .van-button{
      border-radius: 6px;
      font-weight: bold;
      width: 260px;
    }

    /deep/ .van-checkbox__label{
      font-size: 26px;
    }

    /deep/ .van-checkbox__icon--checked .van-icon{
      background-color: #7441a8;
      border-color: #7441a8;
    }
  }

</style>